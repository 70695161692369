<template>
    <div v-if="isAdmin && isMyProject(form.formMeta)">
        <div class="p-2 h5 font-weight-bold">{{ $l("platon.form_settings", "Дастурчи учун") }}</div>

        <platon-link class="mr-2" :link="`/forms/forms/${form.formMeta.id}?_target=modal`">
            <btn icon="fa fa-cog" variant="primary">
                {{ $l("platon.form_setup", "Ойнани созлаш") }}
            </btn>
        </platon-link>

        <platon-link class="mr-2" :link="`/forms/form_elements?form_name=${form.formMeta.name}&_target=modal`">
            <btn icon="fa fa-plus-circle" variant="success">
                {{ $l("platon.form_add_element", "Янги элемент қўшиш") }}
            </btn>
        </platon-link>

        <platon-link class="mr-2" :link="`/tables/form_elements?form_name=${form.formMeta.name}&_target=blank`">
            <btn icon="fa fa-bars" variant="primary">
                {{ $l("platon.form_element_list", "Элементлар рўйхати") }}
            </btn>
        </platon-link>

        <!--					<router-link is="btn" :to="`/forms/forms`" class="float-right" icon="fa fa-tools"-->
        <!--											 variant="warning">-->
        <!--						Техник ҳолатга ўтиш-->
        <!--					</router-link>-->
    </div>
</template>
<script>
import Btn from "@Platon/components/extended/Btn.vue"
import PlatonLink from "@Platon/components/extended/PlatonLink.vue"
import PermissionMixin from "@Platon/mixins/table/PermissionMixin"

export default {
    name: "PlatonFormAdminFooter",
    components: { Btn, PlatonLink },

    mixins: [PermissionMixin],

    props: {
        form: {}
    }
}
</script>
